@use "../config" as *;
@use "../mixins" as *;

$critical: false;
$deferred: true;

.m-image-with-text-block
{
	@include deferred
	{
		border-top: 1px solid $colorDivider;
		@include mq-min($bpDesktop)
		{
			height: 568px;
			position: relative;
			z-index: 1;
			border: 0;
		}
	
		&__title
		{
			padding: 0;
		}
		&__summary
		{
			padding: 15px 0 0 0;
		}
		&__call-to-action
		{
			padding: 15px 0 0 0;
		}
	}
	&__inner
	{
		@include deferred
		{
			margin: 0 auto;
			position: relative;
			text-align: left;
			max-width: $maxPageWidth;
			z-index: 2;
			@include mq-min($bpDesktop)
			{
				height: 100%;
				width: 90%;
			}
		}
	}
	&__content
	{
		@include deferred
		{
			margin: 0 auto;
			padding: 40px 0;
			@include mq-min($bpTablet)
			{
				padding: 45px 0;
			}
			width: 90%;
			max-width: $maxPageWidth;
			@include mq-min($bpDesktop)
			{
				left: 0;
				padding: 0;
				position: absolute;
				top: 60px;
				width: 100%;
				max-width: $maxPageWidth;
				z-index: 1;
			}
		}
		&--position
		{
			&-top
			{
				&-middle
				{
					@include deferred
					{
						@include mq-min($bpDesktop)
						{
							left: 50%;
							@include transform(translateX(-50%));
							html.html--slow-connection &,
							html.prefers-reduce-motion & {
								transform: translateX(-50%) !important;
							}
						}
					}
				}
				&-right
				{
					@include deferred
					{
						@include mq-min($bpDesktop)
						{
							left: auto;
							right: 0;
						}
					}
				}
			}
			&-middle
			{
				&-left
				{
					@include deferred
					{
						@include mq-min($bpDesktop)
						{
							margin-top: -20px;
							top: 50%;
							@include transform(translateY(-50%));

							html.html--slow-connection &,
							html.prefers-reduce-motion & {
								transform: translateY(-50%) !important;
							}
						}
					}
				}
				&-middle
				{
					@include deferred
					{
						@include mq-min($bpDesktop)
						{
							left: 50%;
							margin-top: -20px;
							top: 50%;
							@include transform(translateX(-50%) translateY(-50%));

							html.html--slow-connection &,
							html.prefers-reduce-motion & {
								margin-top: 0;
								transform: translateX(-50%) translateY(-50%) !important;
							}
						}
					}
				}
				&-right
				{
					@include deferred
					{
						@include mq-min($bpDesktop)
						{
							left: auto;
							margin-top: -20px;
							right: 0;
							top: 50%;
							@include transform(translateY(-50%));

							html.html--slow-connection &,
							html.prefers-reduce-motion & {
								transform: translateY(-50%) !important;
							}
						}
					}
				}
			}
			&-bottom
			{
				&-left
				{
					@include deferred
					{
						@include mq-min($bpDesktop)
						{
							bottom: 60px;
							top: auto;
						}
					}
				}
				&-middle
				{
					@include deferred
					{
						@include mq-min($bpDesktop)
						{
							bottom: 60px;
							left: 50%;
							top: auto;
							@include transform(translateX(-50%));

							html.html--slow-connection &,
							html.prefers-reduce-motion & {
								transform: translateX(-50%) !important;
							}
						}
					}
				}
				&-right
				{
					@include deferred
					{
						@include mq-min($bpDesktop)
						{
							bottom: 60px;
							left: auto;
							right: 0;
							top: auto;
						}
					}
				}
			}
		}
		&--colour
		{
			&-light
			{
				@include deferred
				{
					@include mq-min($bpDesktop)
					{
						color: $colorWhite;

						a {

							.js-focus-visible &:focus.focus-visible {
								outline: 4px solid $colorFocusOnDark;
							}
						}
					}
				}
			}			
		}
		&--width
		{
			&-two-thirds-width
			{
				@include deferred
				{
					@include mq-min($bpDesktop)
					{
						max-width: 66.6666%;
					}
				}
			}
			&-half-width
			{
				@include deferred
				{
					@include mq-min($bpDesktop)
					{
						max-width: 50%;
					}
				}
			}
			&-one-third-width
			{
				@include deferred
				{
					@include mq-min($bpDesktop)
					{
						max-width: 33.3333%;
					}
				}
			}
		}
		&.js-scroll-in
		{
			.js:not(.prefers-reduce-motion) &
			{
				@include deferred
				{
					opacity: 0;
					@include mq-min($bpDesktop)
					{
						margin-top: 30px;
					}
				}
				&.scroll-in
				{
					@include deferred
					{
						margin-top: 0;
						opacity: 1;
						@include transition(opacity .3s linear .15s);
						@include mq-min($bpDesktop)
						{
							@include transition(margin-top .3s linear .15s, opacity .3s linear .15s);
						}
					}
				}
			}
		}
	}
	&__image
	{
		@include deferred
		{
			@include mq-min($bpDesktop)
			{
				bottom: 0;
				left: 0;
				position: absolute;
				right: 0;
				top: 0;
				z-index: 1;
			}
		}
	}
}

.m-image-with-text-block + .m-block
{
	@include deferred
	{
		border-top: 0;
	}
}